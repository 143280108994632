html,
body {
  height: 100%;
  margin: 0;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-grow-1 {
  flex-grow: 1;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; /* Ensure it appears above other content */
}

.main-row {
  flex: 1;
  display: flex;
}

.main-content {
  flex-grow: 1;
}

h4 {
  margin-top: 1em;
}
