.subscription-container {
  padding: 2rem;
}

.subscription-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 1.5rem;
  max-width: 1000px;
  margin: 0 auto;
}

.subscription-card {
  position: relative;
  transition: transform 0.2s, box-shadow 0.2s;
  text-align: center;
  padding: 1.5rem;
}

.subscription-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.subscription-card.best-value {
  border-color: #4caf50;
}

.best-value-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #4caf50;
  color: white;
  padding: 4px 12px;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: bold;
}

.price {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.period {
  font-size: 1rem;
  color: #666;
}

.credits {
  color: #666;
  margin-bottom: 1.5rem;
}

.benefits-list {
  text-align: left;
  margin-top: 1rem;
}

.benefits-list ul {
  margin: 0;
  padding-left: 2rem;
  list-style-position: outside;
}

.benefit-item {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  color: #666;
}

.subscription-card.dimmed {
  opacity: 0.5;
}

.subscription-card.selected {
  border: 2px solid #4caf50;
}
