.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(192, 192, 192, 0.1); /* Dim the screen */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it is on top of other elements */
}

.loader {
  display: inline-block;
  font-size: 2rem; /* Increased the size of the dots */
  font-weight: bold;
  text-align: center;
  width: 4rem;
  margin: 0 auto;
}

.loader span {
  display: inline-block;
  animation: loaderAnimation 1s infinite ease-in-out;
  position: relative;
}

.loader span:nth-child(1) {
  animation-delay: 0s;
}

.loader span:nth-child(2) {
  animation-delay: 0.2s;
}

.loader span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes loaderAnimation {
  0%,
  100% {
    transform: translateY(0); /* Dots start at the original position */
  }
  50% {
    transform: translateY(-10px); /* Dots move up */
  }
}
