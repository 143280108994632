@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap");

@media print {
  body {
    margin: 0 !important;
  }
}

.ck-content {
  font-family: "Lato";
  line-height: 1.6;
  word-break: break-word;
}

/* Set a maximum height on the editable area and enable scrolling */
.ck-editor__editable {
  max-height: calc(80vh - 200px); /* Adjust 150px as needed */
  overflow-y: auto;
  padding: 8px;
}

.editor-container_classic-editor .editor-container__editor {
  width: 100%;
  max-width: none;
}

.word-count {
  margin-top: 8px;
  font-size: 14px;
  color: #555;
  text-align: right;
}
